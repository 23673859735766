import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  direction: 'prev' | 'next'
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const SwiperNavigationArrow = ({ className, direction, ...props }: Props) => {
  return (
    <button
      {...props}
      className={twMerge(
        `outline-none w-16 h-16 flex-shrink-0 flex items-center justify-center bg-white rounded-full
        border-[0.5px] border-obe-neutral-500 shadow-obe-neutral-xs focus:outline-none`,
        className,
      )}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='18'
        viewBox='0 0 20 18'
        fill='none'
        style={direction === 'prev' ? { transform: 'rotate(180deg)' } : undefined}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.6365 8.1294L11.7589 0.360051C11.2722 -0.120015 10.4917 -0.120015 10.0055 0.360051C9.51862 0.839637 9.51862 1.60936 10.0055 2.08967L15.7709 7.77611L1.23944 7.77611C0.550862 7.77611 -1.06105e-06 8.31944 -1.12042e-06 8.99861C-1.1798e-06 9.67778 0.550862 10.2211 1.23944 10.2211L15.7709 10.2211L10.0055 15.9083C9.51862 16.3885 9.51862 17.1582 10.0055 17.6378C10.244 17.8731 10.5654 18 10.8777 18C11.1899 18 11.5113 17.8826 11.7499 17.6378L19.627 9.86843C20.1227 9.37943 20.1227 8.60065 19.6365 8.1294Z'
          fill='black'
        />
      </svg>
    </button>
  )
}
