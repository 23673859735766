import { useSwiper } from 'swiper/react'
import { twMerge } from 'tailwind-merge'

import { SwiperNavigationArrow } from 'src/components/Carousel/SwiperNavigationArrow'

type SwiperNavigationButtonProps = {
  direction?: 'next' | 'prev'
  isAbsolutelyPositioned?: boolean
  halfwayPositioned?: boolean
}

export const SwiperNavigationButton: React.FC<SwiperNavigationButtonProps> = ({
  direction = 'next',
  halfwayPositioned = false,
  isAbsolutelyPositioned = true,
}) => {
  const swiper = useSwiper()

  return (
    <div
      className={twMerge(
        'z-10 -translate-y-1/2 transform ',
        direction === 'prev' ? 'left-0 pl-6' : 'right-0 pr-6',
        !halfwayPositioned && isAbsolutelyPositioned ? ' absolute top-1/2' : '',
        halfwayPositioned ? 'absolute bottom-[50%]' : '',
      )}
    >
      <SwiperNavigationArrow
        direction={direction}
        onClick={() => (direction === 'next' ? swiper.slideNext() : swiper.slidePrev())}
        className="swiper-nav-button"
      />
    </div>
  )
}
