import { RichText } from 'src/components/Display/RichText'
import { breakpoint } from 'src/styles'

type HeaderProps = {
  title: string
  titleDecoration?: React.ReactElement
  description: string
  itemPadding?: string
  showNewIndicator?: boolean
  showSeeMore?: boolean
  seeMoreLink?: string
  paddingLeft?: number
}

export const Header = React.memo<HeaderProps>(
  ({
    description,
    itemPadding,
    showNewIndicator,
    title,
    titleDecoration,
    showSeeMore,
    seeMoreLink,
    paddingLeft,
  }) => (
    <Styles.Header compact centraBook itemPadding={itemPadding} paddingLeft={paddingLeft}>
      <Styles.LeftHeader>
        <h2>
          {title}
          {showNewIndicator && <Styles.NewIndicator />}
          {titleDecoration && <Styles.Decoration>{titleDecoration}</Styles.Decoration>}
        </h2>
        <div>{description}</div>
      </Styles.LeftHeader>
      {showSeeMore && (
        <Styles.RightHeader>
          <Styles.SeeMoreText href={seeMoreLink}>See more</Styles.SeeMoreText>
        </Styles.RightHeader>
      )}
    </Styles.Header>
  ),
)

const Styles = {
  Header: styled(RichText.Medium)<{ itemPadding?: string; paddingLeft?: number }>`
    display: flex;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
    padding: 0;
    user-select: none;

    h2 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
      margin-top: 0;
      font-weight: 200;
    }
    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 24px;
      h2 {
        font-size: 24px;
      }
    }

    ${({ itemPadding }) =>
      itemPadding &&
      css`
        padding: ${itemPadding};
      `}
    ${({ paddingLeft }) =>
      paddingLeft &&
      css`
        padding-left: ${paddingLeft}px;
      `}
  `,
  LeftHeader: styled.div``,
  NewIndicator: styled.div`
    color: var(--nearBlack);
    display: inline-block;
    position: relative;
    left: 4px;
    top: -12px;

    ::after {
      content: 'New!';
      background-color: var(--yellow);
      text-align: center;
      font-size: 10px;
      font-weight: normal;
      padding: 5px;
      line-height: 0;
    }
  `,
  SeeMoreText: styled.a`
    font-family: centra, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.3px;
    text-decoration: underline;
    color: var(--nearBlack);
    :visited {
      color: var(--nearBlack);
    }
  `,
  RightHeader: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  `,
  Decoration: styled.div`
    display: inline-block;
    margin-left: 12px;
  `,
}
