import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { isMobile, isTablet } from 'react-device-detect'
import { Swiper, SwiperSlide, type SwiperClass, type SwiperProps } from 'swiper/react'
import { Arrows } from './Arrows'
import { copy } from 'src/styles'
import { Header } from './Header'
import { SwiperNavigationButton } from 'src/components/Carousel/SwiperNavigationButton'
import { isNotOffScreen } from 'src/utils/boundingClientRect'
import { useImpressionTracker } from './useImpressionTracker'
import { useResponsive } from 'src/hooks/useResponsive'
import {
  useCallback,
  useEffect,
  type ReactNode,
  Children,
  type ReactElement,
  type MutableRefObject,
  useRef,
  useState,
  cloneElement,
  isValidElement,
  useMemo,
} from 'react'
import type { SwiperOptions } from 'swiper/types'

export interface SwiperCarouselProps {
  alwaysDisplayNavigation?: boolean
  analytics?: any
  borderRadiusBottom?: number
  carouselName: string
  centeredSlides?: boolean
  children?: ReactNode
  className?: string
  description?: string
  itemPadding?: string
  itemsShown?: number
  loop?: boolean
  onSlideClick?: (
    eventTarget: EventTarget & HTMLElement,
    slideRef: MutableRefObject<HTMLDivElement | null>,
    index: number,
  ) => void
  settings?: SwiperOptions
  showArrows?: boolean
  showDots?: boolean
  smoothScroll?: boolean
  showHeader?: boolean
  slidesPerGroup?: number
  showNewIndicator?: boolean
  spaceBetween?: number
  spaceBetweenArrows?: boolean
  swiperRef?: MutableRefObject<SwiperClass | undefined>
  effect?: string
  title?: string
  showSeeMore?: boolean
  seeMoreLink?: string
  titleDecoration?: ReactElement
  itemWidth?: number
  displayNavigationButton?: boolean
  initialSlideIndex?: number
  showDotsAsSquares?: boolean
  itemHeight?: number | null
  allowTouchMove?: boolean
  handleSwipeEvent?: (direction: string) => void
  setActiveKey?: (arg: null) => void
  isChevron?: boolean
  activeKey?: number
  setWidth?: () => void
  carouselMarginBottom?: number
  pagination?: ReactNode
  swiperOptions?: SwiperProps
}

export const SwiperCarousel = ({
  alwaysDisplayNavigation = false,
  analytics = {},
  borderRadiusBottom = 0,
  carouselName = 'carousel',
  centeredSlides = false,
  children,
  className = 'carousel',
  description = '',
  itemPadding,
  itemsShown = 1,
  loop = true,
  onSlideClick = Function.prototype as () => void,
  settings = {},
  showArrows = true,
  showDots = false,
  smoothScroll = false,
  showHeader = true,
  slidesPerGroup = 1,
  showNewIndicator = false,
  spaceBetween = 10,
  spaceBetweenArrows = true,
  swiperRef,
  effect,
  title,
  showSeeMore,
  seeMoreLink,
  titleDecoration,
  itemWidth,
  displayNavigationButton = false,
  initialSlideIndex,
  showDotsAsSquares = false,
  carouselMarginBottom,
  itemHeight = null,
  pagination,
  swiperOptions,
  ...props
}: SwiperCarouselProps) => {
  const childrenCount = useMemo(() => Children.count(children), [children])
  const { isMediumAndUp } = useResponsive()
  const allowTouchMove = isMobile || isTablet || props.allowTouchMove
  const activeSlideRef = useRef<HTMLDivElement>(null)
  const isUserInteraction = useRef(false)
  const showNavigation = useMemo(() => childrenCount > itemsShown, [childrenCount, itemsShown])
  const [showPreviousButton, setShowPreviousButton] = useState(
    displayNavigationButton && showNavigation && loop,
  )
  const [showNextButton, setShowNextButton] = useState(displayNavigationButton && showNavigation)

  useEffect(() => {
    if (showNavigation && !alwaysDisplayNavigation) {
      setShowNextButton(displayNavigationButton)
    }
  }, [alwaysDisplayNavigation, displayNavigationButton, loop, showNavigation])

  const handleSwipeEvent = (direction: string) => {
    if (typeof props.handleSwipeEvent === 'function') {
      props.handleSwipeEvent(direction)
    } else if (isUserInteraction.current) {
      isUserInteraction.current = false

      obe.analytics.track(`Swiped Carousel ${direction}`, {
        carouselName: analytics?.carousel?.tracking?.carouselName || title,
      })
    }
  }
  const { handleActiveIndexChange, inViewRef } = useImpressionTracker(analytics, itemsShown)
  const onInitHandler = useCallback(
    (swiper: SwiperClass) => {
      if (swiperRef) {
        swiperRef.current = swiper
      } else if (showDots) {
        swiper.activeIndex = 0
      }
    },
    [showDots, swiperRef],
  )

  const onActiveIndexChangeHandler = useCallback(
    ({ realIndex }: SwiperClass) => {
      if (showNavigation && displayNavigationButton && !alwaysDisplayNavigation) {
        setShowPreviousButton(realIndex > 0)
        setShowNextButton(realIndex < childrenCount - itemsShown)
      }
      handleActiveIndexChange(realIndex)
    },
    [
      alwaysDisplayNavigation,
      childrenCount,
      displayNavigationButton,
      handleActiveIndexChange,
      itemsShown,
      showNavigation,
    ],
  )

  const containerProps = {
    alwaysDisplayNavigation,
    centeredSlides,
    className,
    itemsShown,
    smoothScroll,
    borderRadiusBottom,
    ref: inViewRef,
    showDots,
    itemWidth,
    showDotsAsSquares,
    itemHeight,
    carouselMarginBottom,
  }

  const carouselProps = {
    modules: [Autoplay, Navigation, Pagination, Scrollbar],
    allowTouchMove,
    ...(initialSlideIndex && { initialSlide: initialSlideIndex }),
    navigation: {
      prevEl: `.prev-${carouselName}`,
      nextEl: `.next-${carouselName}`,
    },
    onActiveIndexChange: (swiper: SwiperClass) => onActiveIndexChangeHandler(swiper),
    onInit: (swiper: SwiperClass) => onInitHandler(swiper),
    onSlideNextTransitionEnd: () => handleSwipeEvent('Left'),
    onSlidePrevTransitionEnd: () => handleSwipeEvent('Right'),
    onTouchStart: () => (isUserInteraction.current = true),
    slidesPerView: itemsShown,
    spaceBetween,
    ...(showDots && { pagination: { clickable: true } }),
    effect,
    slidesPerGroup,
    ...settings,
    loop: (loop || settings.loop) && Children.count(children) > itemsShown,
    ...(displayNavigationButton && { className: 'swiper-nav-fade' }),
  }

  if (carouselName === 'hero') {
    carouselProps.onSlideChange = () => {
      if (activeSlideRef?.current) {
        activeSlideRef?.current?.classList.remove('active-key')
        props.setActiveKey && props.setActiveKey(null)
      }
    }
  }

  return (
    <Styles.Container {...containerProps}>
      {showHeader && (
        <Header
          description={description}
          itemPadding={itemPadding}
          showNewIndicator={showNewIndicator}
          title={title ?? ''}
          titleDecoration={titleDecoration}
          showSeeMore={showSeeMore}
          seeMoreLink={seeMoreLink}
        />
      )}

      {Children.count(children) > 0 && (
        <Swiper {...carouselProps} {...swiperOptions}>
          {showPreviousButton && isMediumAndUp && <SwiperNavigationButton direction='prev' />}
          {Children.map(children, (child, index) => {
            if (!isValidElement(child)) return
            return (
              <SwiperSlide
                onClick={({ currentTarget }) => onSlideClick(currentTarget, activeSlideRef, index)}
              >
                {carouselName === 'hero'
                  ? cloneElement(child as ReactElement, {
                      _itemWidth: itemWidth,
                      activeKey: props.activeKey,
                      activeSlideRef: activeSlideRef.current,
                      isActive:
                        (activeSlideRef?.current && isNotOffScreen(activeSlideRef.current)) ||
                        isMediumAndUp
                          ? index === props?.activeKey
                          : false,
                      itemsShown,
                      setWidth: props.setWidth,
                    })
                  : child}
              </SwiperSlide>
            )
          })}
          {showArrows && isMediumAndUp && (
            <Arrows
              isChevron={!!props?.isChevron}
              allowTouchMove={!!allowTouchMove}
              arrowNextClassName={`next-${carouselName}`}
              arrowPrevClassName={`prev-${carouselName}`}
              carouselName={carouselName}
              itemPadding={itemPadding ?? ''}
              spaceBetweenArrows={spaceBetweenArrows}
              title={analytics?.carousel?.tracking?.carouselName || title}
            />
          )}
          {showNextButton && isMediumAndUp && <SwiperNavigationButton />}
          {pagination}
        </Swiper>
      )}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div<{
    borderRadiusBottom: number
    showDots?: boolean
    smoothScroll?: boolean
    itemHeight: number | null
    itemsShown?: number
    showDotsAsSquares?: boolean
    carouselMarginBottom?: number
    alwaysDisplayNavigation?: boolean
  }>`
    margin-bottom: 32px;
    position: relative;

    ${({ carouselMarginBottom }) =>
      carouselMarginBottom !== undefined &&
      css`
        margin-bottom: ${carouselMarginBottom}px;
      `}

    ${({ borderRadiusBottom }) =>
      borderRadiusBottom &&
      css`
        border-bottom-left-radius: ${borderRadiusBottom}px;
        border-bottom-right-radius: ${borderRadiusBottom}px;
        overflow: hidden;
      `}

    .swiper-pagination-horizontal {
      bottom: 5px;
    }

    .swiper-pagination {
      max-width: 500px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: none;

      ${({ showDots }) =>
        showDots &&
        css`
          display: flex;
          justify-content: center;
          position: absolute !important;
        `}
    }

    .swiper-wrapper {
      ${({ smoothScroll }) =>
        smoothScroll &&
        css`
          transition-timing-function: linear;
        `}

      ${({ itemHeight }) =>
        (itemHeight ?? 0) > 0 &&
        css`
          min-height: ${itemHeight}px;
        `}
    }

    .swiper-container,
    .swiper-container-initialized,
    .swiper-container-horizontal,
    .swiper-container-pointer-events {
      ${({ showDots }: { showDots?: boolean }) =>
        showDots &&
        css`
          display: flex;
          align-items: flex-end;
          flex-direction: column-reverse;
        `}
    }

    .swiper-slide,
    .swiper-slide-active {
      ${({ itemsShown }) =>
        !itemsShown &&
        css`
          width: 70% !important;
        `}
    }

    .swiper-pagination {
      z-index: 1;
      ${({ showDotsAsSquares }: { showDotsAsSquares?: boolean }) =>
        !!showDotsAsSquares &&
        css`
          width: 195px;
          right: -163px;
        `}
    }

    .swiper-pagination-bullet {
      padding-left: 0;
      margin-right: 10px;
      margin-left: 10px;

      ${({ showDotsAsSquares }: { showDotsAsSquares?: boolean }) =>
        !!showDotsAsSquares &&
        css`
          border-radius: 0px;
          width: 40px;
          height: 3px;
        `}
    }

    .swiper-pagination-bullet-active {
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      background-color: #000;
      opacity: 0.5;
      transition: opacity 0.6s ease;
    }

    .swiper-slide {
      height: unset;
    }

    ${({ alwaysDisplayNavigation }) =>
      !alwaysDisplayNavigation &&
      css`
        .swiper-nav-fade .swiper-nav-button {
          opacity: 0;
          transition: opacity 0.4s ease;
        }
        .swiper-nav-fade:hover .swiper-nav-button {
          opacity: 1;
          transition: opacity 0.4s ease;
        }
      `}
  `,
  Title: styled.div`
    ${copy.headerXlarge}
    margin-bottom: 18px;
    margin-top: 0;
    padding: 0 19px;
    font-weight: 200;
  `,

  ArrowContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
}
